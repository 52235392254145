import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProjectBanner from '../../components/projectBanner';

const PortfolioPitchmatic = () => {
  const data = useStaticQuery(graphql`
    query {
      pitchmaticMac: file(relativePath: { eq: "pitchmaticMac.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pitchmaticLanding: file(relativePath: { eq: "pitchmaticLanding.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pitchmaticFlowbuilder: file(relativePath: { eq: "pitchmaticFlowbuilder.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pitchmaticLeadCollection: file(relativePath: { eq: "pitchmaticLeadCollection.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pitchmaticSegmentation: file(relativePath: { eq: "pitchmaticSegmentation.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pitchmaticMailBuilder: file(relativePath: { eq: "pitchmaticMailBuilder.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const contentArray = [
    {
      title: 'No Code Drip Campaign Builder',
      description: 'Creating an email marketing campaign should be easy as a walk in the park. Drag and Drop flow builder makes it easy and intuitive for designing and visualising the sales funnel.',
      image: <Img className="w-100 mx-auto d-block" fluid={data.pitchmaticFlowbuilder.childImageSharp.fluid} alt="" />,
    },
    {
      title: 'Lead Collection',
      description: 'Automated Lead collection from across the internet is an integral feature of PitchMatic. Microservices for collecting Leads based on keywords and location from various online directories was a challenging feature to build.',
      image: <Img className="w-100 mx-auto d-block" fluid={data.pitchmaticLeadCollection.childImageSharp.fluid} alt="" />,
    },
    {
      title: 'Segmentation',
      description: 'Segmentation of leads based on conditions and lead score was an important feature for this product as it add more value to the collected lead.',
      image: <Img className="w-100 mx-auto d-block" fluid={data.pitchmaticSegmentation.childImageSharp.fluid} alt="" />,
    },
    {
      title: 'Email Designer',
      description: 'A full fledged wysiwyg email designer is essential for users to design their email template right within the application. We built a feature rich drag and drop email editor for Pitchmatic..',
      image: <Img className="w-100 mx-auto d-block" fluid={data.pitchmaticMailBuilder.childImageSharp.fluid} alt="" />,
    },
  ];

  return (
    <Layout pageInfo={{ pageName: 'pitchmatic' }} noLayoutbanner>
      <SEO title="PitchMatic | Projects" />
      <ProjectBanner bgColor="#3597EC" title="PitchMatic" description="Automated Lead Collection And Drip Email Marketing Platform" image={data.pitchmaticMac.childImageSharp.fluid} />
      <TheProject data={data} />
      <Landing data={data} />
      <Content contentArray={contentArray} />
    </Layout>
  );
};

export default PortfolioPitchmatic;

const TheProject = () => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5">
        <Col xs={12} lg={4}>
          <h4 className="text-left font-weight-bolder">The Project</h4>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto underlineHr" />
        </Col>
        <Col xs={12} lg={8}>
          <p>PitchMatic is an email automation platform having powerful features like drip campaign, automated lead collection, webhooks and more. The challenge was to build an intuitive web app for users to create and manage their campaigns, and an array of micro services for scraping leads, segmenting them, sending emails, tracking statuses and more.</p>
        </Col>
      </Row>
    </Container>
  );
};
const Landing = (props) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 align-items-md-center flex-column-reverse flex-lg-row">
        <Col xs={12} lg={8}>
          <Img className="w-100 mx-auto d-block" fluid={props.data.pitchmaticLanding.childImageSharp.fluid} alt="" />
        </Col>
        <Col xs={12} lg={4} className="landing_pitchmatic">
          <h4 className="text-left font-weight-bolder">Landing Page</h4>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto underlineHr" />
          <p>There are hundreds of email marketing platforms in the market. We had to make PitchMatic standout and demonstrate it’s unique features using the landing page, which forms the first impression of the product</p>
        </Col>
      </Row>
    </Container>
  );
};

const Content = (props) => {
  return (
    <Container>
      {props.contentArray.map((item) => {
        return (
          <Row noGutters className="py-5 my-5 align-items-md-center">
            <Col xs={12} lg={4}>
              <h4 className="text-left font-weight-bolder">{item.title}</h4>
              <hr className="info-color mb-4 mt-0 d-inline-block mx-auto underlineHr" />
              <p>{item.description}</p>
            </Col>
            <Col xs={12} lg={8}>
              {item.image}
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};
